import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useParams } from "react-router-dom";
import { StorefrontSettingsViewModel } from "../../apiclient";

const StripeCheckoutForm = (props : any) => {
  const stripe = useStripe();
  const elements = useElements();
  //let { id } = useParams();
  const [errorMessage, setErrorMessage] = useState(null as any);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [payButtonText, setPayButtonText] = useState("Pay now");
  const [settings, setSettings] = useState(props.settings as StorefrontSettingsViewModel);

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsSubmitting(true);
    setPayButtonText("Processing...");

    var returnUrl = `https://${settings.wrappedStorefrontSubdomain}.wrappedgiftcards.store/checkout/complete`;
    if(props.paymentType === "topup"){
      //returnUrl = `https://localhost:44434/topup/checkout/complete/${props.topupGiftCardId}`; 
      returnUrl = `https://${settings.wrappedStorefrontSubdomain}.wrappedgiftcards.store/topup/checkout/complete/${props.topupGiftCardId}`;
    }

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
          return_url: returnUrl,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
      setIsSubmitting(false);
      setPayButtonText("Pay now");
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="py-5">
        <PaymentElement />
      </div>
      <button
        disabled={!stripe || isSubmitting}
        type="submit"
        className="btn btn-primary w-full btn-custom"
      >
        {payButtonText}
      </button>
      {/* Show error message to your customers */}
      {errorMessage && (
        <div className="text-danger pt-5 text-center text-sm">
          {errorMessage}
        </div>
      )}
    </form>
  );
};

export default StripeCheckoutForm;
