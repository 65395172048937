import React, {
  Component,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import {
  Button,
  Row,
  Modal,
  Form,
  Container,
  Col,
  InputGroup,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  Audience,
  GiftCardImageAssetViewModel,
  GiftToFriendPageSettingsViewModel,
  StorefrontApi,
  StorefrontOrderViewModel,
  StorefrontSettingsViewModel,
  UploadGiftCardsVideoMessageResultPayload,
} from "../../../apiclient";
import { apiConfig } from "../../../config";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import "react-quill/dist/quill.snow.css";
import "../PublicStorefront.css";
import TimezoneSelect from "react-timezone-select";

//import "./assets.css";
const PublicStorefrontTopupGiftCardDetailsComponent = (props: any) => {
  const [audience, setAudience] = useState("SomeoneElse");
  const [sendOption, setSendOption] = useState("now");
  const [model, setModel] = useState(props.model as StorefrontOrderViewModel);
  const [settings, setSettings] = useState(
    props.settings as StorefrontSettingsViewModel
  );

  const [themeModel, setThemeModel] = useState(
    {} as GiftToFriendPageSettingsViewModel
  );
  const [images, setImages] = useState([] as GiftCardImageAssetViewModel[]);
  const [validated, setValidated] = useState(false);
  const [isLoading, setLoading] = useState(false as boolean);
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [selectedImage, setSelectedImage] = useState(
    {} as GiftCardImageAssetViewModel
  );

  let storefrontApi = new StorefrontApi(
    apiConfig.configuration,
    apiConfig.baseUrl
  );

  // const fetchThemeData = () => {
  //   storefrontApi
  //     .storefrontGiftToFriendPagePublicGet()
  //     .then((response) => {
  //       var data = response.data;
  //       //if(data.useLegacy){
  //       //  window.location.href = `/public/gift-to-friend-legacy/${id}`;
  //       //}
  //       setThemeModel(data);
  //     })
  //     .catch((error: any) => {});
  // };

  const [showModal, setShowModal] = useState(false);
  //let { id } = useParams();

  useEffect(() => {
    if (images.length > 0) {
      setSelectedImage(images[0]);
      setModel((values: StorefrontOrderViewModel) => ({
        ...values,
        giftImageUrl: images[0].imageUrl,
      }));
    }
  }, [images]);

  const getLocalDate = (dateStr: string) => {
    const currentDateTime = new Date(dateStr);
    const year = currentDateTime.getFullYear();
    const month = String(currentDateTime.getMonth() + 1).padStart(2, "0");
    const day = String(currentDateTime.getDate()).padStart(2, "0");
    const hours = String(currentDateTime.getHours()).padStart(2, "0");
    const minutes = String(currentDateTime.getMinutes()).padStart(2, "0");

    var localDate = `${year}-${month}-${day}T${hours}:${minutes}`;
    return localDate;
  };

  useEffect(() => {
    if (model) {
      props.onUpdated(model);
    }
  }, [model]);

  useEffect(() => {
    if (model) {
      setModel((values: StorefrontOrderViewModel) => ({
        ...values,
        giftScheduledFor: getLocalDate(new Date().toString()),
        quantity: 1,
        accountId: model.accountId,
        currency: model.currency ?? "nzd",
        audience: model.audience ?? "SomeoneElse",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        recipientEmailAddress: model.recipientEmailAddress,
        giftedFromEmail: model.giftedFromEmail,
        giftedFromName: model.giftedFromName,
      }));

      //fetchThemeData();
    }
  }, []);

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (form.checkValidity() === true) {
      props.onSubmit();
    }
  };

  const onNavigateToPage = (pageName: string) => {
    props.onNavigateToPage(pageName);
  };

  const handleChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setModel((values: StorefrontOrderViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  return (
    <React.Fragment>
      {model && (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="row g-5 p-5">
            <div className="col-md-12 p-5 pb-0 pt-0">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a
                      className="cursor-pointer"
                      onClick={() => onNavigateToPage("cart")}
                    >
                      Cart
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item active font-bold"
                    aria-current="page"
                  >
                    Information
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    Payment
                  </li>
                </ol>
              </nav>
            </div>
            <div className="text-center">
              {model.quantity! > 1 ? (
                <h4 className="font-semibold mb-1">
                  {model.quantity} x {model.productName}
                </h4>
              ) : (
                <h4 className="font-semibold mb-1">${model.amount} gift card top-up</h4>
              )}

              <p className="text-sm text-muted">
                For use at {settings.companyName}
              </p>
            </div>

            <>
              {/* <div className="col-md-12">
                <div className="">
                  <label className="form-label" htmlFor="giftedFromName">
                    Your name
                  </label>
                  <Form.Control
                    required
                    type="text"
                    name="giftedFromName"
                    value={model.giftedFromName!}
                    onChange={handleChange}
                  />
                </div>
              </div> */}
              <div className="col-md-12 pt-5">
                <div className="">
                  <label className="form-label" htmlFor="giftedFromEmail">
                    Your email address (for the receipt)
                  </label>
                  <Form.Control
                    required
                    type="text"
                    name="giftedFromEmail"
                    value={model.giftedFromEmail!}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="">
                  <label className="form-label" htmlFor="recipientEmailAddress">
                    What email address should we send the updated gift card?
                  </label>
                  <Form.Control
                    required
                    type="text"
                    name="recipientEmailAddress"
                    value={model.recipientEmailAddress!}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <button type="submit" className="btn btn-primary w-full btn-custom">
                  Continue <i className="ml-10 bi bi-arrow-right" />
                </button>
              </div>
            </>
          </div>
        </Form>
      )}
    </React.Fragment>
  );
};

export default PublicStorefrontTopupGiftCardDetailsComponent;
