import React, {
  Component,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import {
  Button,
  Row,
  Modal,
  Form,
  Container,
  Col,
  InputGroup,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
 StorefrontApi,
  StorefrontSettingsViewModel,
} from "../../apiclient";
import { apiConfig } from "../../config";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import "react-quill/dist/quill.snow.css";
import "./PublicStorefront.css";

//import "./assets.css";
const PublicStorefrontComingSoonComponent = (props: any) => {
  const [model, setModel] = useState(props.model as StorefrontSettingsViewModel);

  return (
    <React.Fragment>
      <div className="pb-10">
      <h2 className="text-center">Coming soon</h2>
      </div>
    </React.Fragment>
  );
};

export default PublicStorefrontComingSoonComponent;
