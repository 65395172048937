import React, {
  Component,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import {
  Button,
  Row,
  Modal,
  Form,
  Container,
  Col,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import { StorefrontApi } from "../../apiclient";
import { apiConfig } from "../../config";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import "react-quill/dist/quill.snow.css";
import "./PublicStorefront.css";
import {
  //GiftToFriendPageSettingsViewModel,
  GiftCardDetailViewModel,
  // SendGiftCardToFriendViewModel,
  // UploadGiftCardsVideoMessageResultPayload,
  // GiftCardImageAssetViewModel,
  StorefrontOrderViewModel,
  StorefrontSettingsViewModel
} from "../../apiclient/models";
// import Util from "../../js/util";
// import ReactQuill from "react-quill";
//import TimezoneSelect from "react-timezone-select";
// import { toLocaleString } from "../../data/admins";
//import { escape, unescape } from "lodash";
// import VideoRecorderModal from "../public_customer/VideoRecorderModal";
// import PublicStorefrontAddToCartComponent from "./PublicStorefrontAddToCartComponent";
// import PublicStorefrontGiftCardDetailsComponent from "./PublicStorefrontGiftCardDetailsComponent";
// import PublicStorefrontPaymentComponent from "./PublicStorefrontPaymentComponent";
// import PublicStorefrontCheckBalanceComponent from "./PublicStorefrontCheckBalanceComponent";
import { Link, useSearchParams } from "react-router-dom";
import PublicStorefrontOrderSummaryComponent from "./PublicStorefrontOrderSummaryComponent";
import { useNavigate } from "react-router";
import CurrencyUtil from "../../Helpers/CurrencyUtil";
const dayjs = require("dayjs");
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));
dayjs.extend(require("dayjs/plugin/relativeTime"));

//import "./assets.css";
const PublicStorefrontPaymentCompletePage = () => {
  const [showCheckBalanceForm, setShowCheckBalanceForm] = useState(false);
  const [model, setModel] = useState({} as StorefrontOrderViewModel);
  const [checkBalanceCode, setCheckBalanceCode] = useState("");
  const [validated, setValidated] = useState(false);
  const [showInvalidCodeError, setShowInvalidCodeError] = useState(false);
  const [isLoading, setLoading] = useState(false as boolean);
  const [showPage, setShowPage] = useState("complete");
  const [searchParams, setSearchParams] = useSearchParams();
  const paymentIntentId = searchParams.get("payment_intent") ?? "";
  const [orderTotal, setOrderTotal] = useState(0);
  const [settings, setSettings] = useState({} as StorefrontSettingsViewModel);

  const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  //let { id } = useParams();
  const navigate = useNavigate();

  let storefrontApi = new StorefrontApi(
    apiConfig.configuration,
    apiConfig.baseUrl
  );

  const [giftCardModel, setGiftCardModel] = useState(
    {} as GiftCardDetailViewModel
  );

  const handleCheckBalanceSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (form.checkValidity() === true) {
      getGiftCardByCode();
    }
  };

  const fetchSettings = () => {
    storefrontApi
      .storefrontSettingsGet()
      .then((response) => {
        var data = response.data;
        if (data) {
          setSettings(data);
        } else {
          // account not found
          navigate("/account-not-found");
        }
      })
      .catch((error: any) => {
        console.log("get error", error);
        navigate("/account-not-found");
      });
  };

  const handleCheckBalanceCodeChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setCheckBalanceCode(value);
  };

  const showBalancePageOnClick = () => {
    setShowPage("balance");
    setShowCheckBalanceForm(false);
  };

  const getGiftCardByCode = () => {
    if (checkBalanceCode) {
      storefrontApi.storefrontGetByCodeCodeGet(checkBalanceCode, paymentIntentId)
        .then((response) => {
          if (response.data) {
            setGiftCardModel(response.data);
            setShowInvalidCodeError(false);
            showBalancePageOnClick();
          } else {
            toast.error("Invalid code.");
            setShowInvalidCodeError(true);
          }
        })
        .catch((error: any) => {
          toast.error("An error occurred check code.");
        });
    }
  };

  const initOrderModel = () => {
    //var model = props.model as StorefrontOrderViewModel;
    storefrontApi
      .storefrontGetOrProcessOrderGet(paymentIntentId)
      .then((response) => {
        setModel(response.data!);
        setOrderTotal(response.data.amount! * response.data.quantity!);
      })
      .catch((error: any) => {});
  };

  useEffect(() => {
    fetchSettings();
    initOrderModel();
  }, []);

  const checkBalanceOnClick = () => {
    setShowCheckBalanceForm(true);
  };

  return (
    <React.Fragment>
      <div className="bg-surface-secondary">
        <div className="container py-10">
          <div className="row">
            <div className="col-lg-5 mx-auto">
              <div className="card shadow border-0 mb-10">
                <div className="card-body p-0">
                  <div className="p-2">
                    <img
                      alt=""
                      src={settings.headerImageUrl}
                      className="card-img"
                    />
                  </div>

                  {isLoading ? (
                    <>
                      <Spinner animation="border" />
                    </>
                  ) : (
                    model && (
                      <div className="row g-5">
                        <div className="text-center p-5">
                          <h2 className="font-semibold mb-1">Order received</h2>
                          <p className="text-sm text-muted">

                            Thank you for your order, payment of {CurrencyUtil.formatCurrency((model.totalAmount! - model.totalDiscount!) + model.totalFees!, settings.isoCurrencyCode)}{" "}
                            has been taken. <br />
                            We've just sent an email to{" "}
                            {model.recipientEmailAddress}.
                          </p>
                        </div>
                        <div className="col-12">
                          <div className="text-center p-5">
                            {model && model.quantity! > 1 ? ( <h4 className="font-semibold mb-1">Gift cards</h4>) : ( <h4 className="font-semibold mb-1">Gift card</h4>)}
                           
                          </div>
                          <div className="list-group list-group-flush p-5 pt-0">
                            {model &&
                              model.giftCards?.map((code, i) => (
                                <div
                                  className=" list-group-item d-flex align-items-center"
                                  key={"code-" + i}
                                >
                                  <div className="flex-fill">
                                    <div className="d-block h6 font-semibold mb-1">
                                      Code: {code.giftCardCode}
                                    </div>
                                    <span className="d-block text-sm mb-2">
                                    {code.expiresAt && dayjs(code.expiresAt)
                                        .utc()
                                        .tz(clientTimezone)
                                        .format("DD MMM YYYY")}
                                    </span>
                                    <div className="d-flex mx-n1"></div>
                                  </div>
                                  <div className="ms-auto text-end">
                                    {code.isGifted?(
                                      <a
                                      target="_blank"
                                      className="btn btn-sm btn-primary btn-custom"
                                      href={`/GiftCard/GiftedPreview/${code.giftCardId}`}
                                      rel="noreferrer"
                                    >
                                      View
                                    </a>
                                    ):(
                                    <a
                                      target="_blank"
                                      className="btn btn-sm btn-primary btn-custom"
                                      href={`/GiftCard/Preview/${code.giftCardId}`}
                                      rel="noreferrer"
                                    >
                                      View
                                    </a>
                                    )}
                                    
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                        {model && model.giftCardCodes && (
                          <PublicStorefrontOrderSummaryComponent
                            model={model}
                            paymentComplete={true}
                          />
                        )}

                      </div>
                      
                    )
                  )}
                  <div className="card-footer text-center">
                  <a
                      className="btn btn-sm btn-neutral w-full"
                      href={`/`}
                    >
                      Buy more gift cards
                    </a>
                  </div>
                  <div className="card-footer text-center">
                    {!showCheckBalanceForm ? (
                      <a
                        className="text-sm cursor-pointer"
                        onClick={checkBalanceOnClick}
                      >
                        Check your gift card balance
                      </a>
                    ) : (
                      <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleCheckBalanceSubmit}
                      >
                        <InputGroup className="mb-3">
                          <Form.Control
                            required
                            name="checkBalanceCode"
                            value={checkBalanceCode}
                            size="sm"
                            placeholder="Enter gift card code..."
                            aria-label="Enter gift card code..."
                            onChange={handleCheckBalanceCodeChange}
                          />
                          <Button
                            type="submit"
                            //onClick={showBalancePageOnClick}
                            size="sm"
                            variant="primary"
                            className="btn-custom"
                          >
                            Check balance
                          </Button>
                        </InputGroup>
                        {showInvalidCodeError && (
                          <div className="font-semibold text-danger text-sm text-start">
                            No gift card found with the code '{checkBalanceCode}'
                          </div>
                        )}
                      </Form>
                    )}
                  </div>
                </div>
              </div>
              <footer className="">
                <div className="container">
                  <a
                    target="_blank"
                    href="https://wrappedgiftcards.com?utm_source=branding&utm_medium=web"
                  >
                    <div className="row justify-content-center">
                      <div className="col-auto">
                        <img
                          src="/img/logos/wrapped-logo-grey.png"
                          className="h-10"
                          alt="..."
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center text-center">
                      <div className="col-xl-9">
                        <p className="text-sm text-muted opacity-80">
                          Gift cards for omnichannel brands
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PublicStorefrontPaymentCompletePage;
