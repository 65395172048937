import React, { Component } from 'react';

export class AccountNotFound extends Component {
    static displayName = AccountNotFound.name;

    render() {
        return (
            <div className="mt-20 text-center">
                <h4>Account not found.</h4>
            </div>
        );
    }
}
