import React, {
  Component,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import {
  Button,
  Row,
  Modal,
  Form,
  Container,
  Col,
  InputGroup,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import { StorefrontApi } from "../../../apiclient";
import { apiConfig } from "../../../config";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import "react-quill/dist/quill.snow.css";
import "../PublicStorefront.css";
import {
  GiftCardDetailViewModel,
  StorefrontOrderViewModel,
  StorefrontSettingsViewModel,
} from "../../../apiclient/models";
//import { escape, unescape } from "lodash";
// import VideoRecorderModal from "../public_customer/VideoRecorderModal";
import PublicStorefrontTopupAddToCartComponent from "./PublicStorefrontTopupAddToCartComponent";
import PublicStorefrontPaymentComponent from "./PublicStorefrontTopupPaymentComponent";
import { useNavigate } from "react-router";
import PublicStorefrontComingSoonComponent from "../PublicStorefrontComingSoonComponent";
import PublicStorefrontCheckBalanceComponent from "../PublicStorefrontCheckBalanceComponent";
import PublicStorefrontTopupGiftCardDetailsComponent from "./PublicStorefrontTopupGiftCardDetailsComponent";

//import "./assets.css";
const PublicStorefrontTopupCheckoutPage = () => {
  const [showCheckBalanceForm, setShowCheckBalanceForm] = useState(false);
  const [showPage, setShowPage] = useState("cart");
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [model, setModel] = useState({} as StorefrontOrderViewModel);
  const [checkBalanceCode, setCheckBalanceCode] = useState("");
  const [validated, setValidated] = useState(false);
  const [showInvalidCodeError, setShowInvalidCodeError] = useState(false);
  const [giftCardModel, setGiftCardModel] = useState(
    {} as GiftCardDetailViewModel
  );
  const [refreshCount, setRefreshCount] = useState(0);
  const [settings, setSettings] = useState({} as StorefrontSettingsViewModel);
  let { id } = useParams();

  let storefrontApi = new StorefrontApi(
    apiConfig.configuration,
    apiConfig.baseUrl
  );

  const navigate = useNavigate();

  const handleCheckBalanceSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (form.checkValidity() === true) {
      getGiftCardByCode();
    }
  };

  const handleCheckBalanceCodeChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setCheckBalanceCode(value);
  };

  const getGiftCardByCode = () => {
    if (checkBalanceCode) {
      storefrontApi
        .storefrontGetByCodeCodeGet(checkBalanceCode)
        .then((response) => {
          if (response.data) {
            setGiftCardModel(response.data);
            setShowInvalidCodeError(false);
            showBalancePageOnClick();
          } else {
            toast.error("Invalid code.");
            setShowInvalidCodeError(true);
          }
        })
        .catch((error: any) => {
          toast.error("An error occurred check code.");
        });
    }
  };

  const getGiftCardById = () => {
    if (id) {
      storefrontApi
        .storefrontGetByIdIdGet(id!)
        .then((response) => {
          if (response.data) {
            setGiftCardModel(response.data);
            setShowInvalidCodeError(false);
            //showBalancePageOnClick();
          } else {
            toast.error("Invalid code.");
            setShowInvalidCodeError(true);
          }
        })
        .catch((error: any) => {
          toast.error("An error occurred check code.");
        });
    }
  };

  const initOrderModel = () => {
    //var model = props.model as StorefrontOrderViewModel;
    storefrontApi
      .storefrontInitStorefrontTopupOrderGiftCardToTopupIdPost(id!)
      .then((response) => {
        setModel(response.data!);
      })
      .catch((error: any) => {});
  };

  useEffect(() => {
    initOrderModel();
    fetchSettings();
    getGiftCardById();
  }, []);

  const fetchSettings = () => {
    storefrontApi
      .storefrontSettingsGet()
      .then((response) => {
        var data = response.data;
        if (data) {
          if (!data.isPublished) {
            data.hideCheckBalance = true;
            setShowPage("comingsoon");
          }

          setSettings(data);

          if (!data.shouldAllowTopups) {
            toast.error("Top-ups are not supported");
            navigate("/");
          }
        } else {
          // account not found
          navigate("/account-not-found");
        }
      })
      .catch((error: any) => {
        console.log("get error", error);
        navigate("/account-not-found");
      });
  };

  const checkBalanceOnClick = () => {
    setShowCheckBalanceForm(true);
  };

  const showDetailsPageOnClick = (amount: number, productName: string) => {
    setSelectedAmount(amount);
    setShowPage("details");
    setShowCheckBalanceForm(false);
    setModel((values: StorefrontOrderViewModel) => ({
      ...values,
      amount: amount,
      productName: productName,
    }));
  };

  const showCartPageOnClick = () => {
    setShowPage("cart");
    setShowCheckBalanceForm(false);
  };

  const showPaymentPageOnClick = (amount: number) => {
    setShowPage("payment");
    setShowCheckBalanceForm(false);
  };

  const onUpdated = (updatedModel: StorefrontOrderViewModel) => {
    setModel(updatedModel);
    setRefreshCount(refreshCount + 1);
  };

  const showBalancePageOnClick = () => {
    setShowPage("balance");
    setShowCheckBalanceForm(false);
  };

  const onNavigateToPage = (pageName: string) => {
    setShowPage(pageName);
    setShowCheckBalanceForm(false);
  };

  return (
    <React.Fragment>
      <div className="bg-surface-secondary">
        <div className="container py-10">
          <div className="row">
            <div className="col-lg-5 mx-auto">
              <div className="card shadow border-0 mb-10">
                <div className="card-body p-0">
                  <div className="p-2">
                    <img
                      alt=""
                      src={settings.headerImageUrl}
                      className="card-img fit-contain-maxh-300"
                    />
                  </div>
                  {/* {settings && settings.description && (
                    <div
                      className="text-center"
                      dangerouslySetInnerHTML={{
                        __html: settings.description!,
                      }}
                    />
                  )} */}
                  {settings && (
                    <p className="text-center h4 pt-5">
                      Your gift card for {settings.companyName}
                    </p>
                  )}
                  {showPage === "comingsoon" && (
                    <PublicStorefrontComingSoonComponent
                      model={settings}
                      giftCardModel={giftCardModel}
                    />
                  )}
                  {showPage === "cart" && (
                    <>
                      {giftCardModel && (
                        <div className="col-12">
                          <div className="d-flex mt-7">
                            <div className="col-6 text-center">
                              <span className="d-block text-sm">Code</span>
                              <div className="h4 font-bolder">
                                {giftCardModel.code}
                              </div>
                            </div>
                            <div className="col-6 text-center">
                              <span className="d-block text-sm">
                                Remaining balance
                              </span>
                              <div className="h4 font-bolder">
                                {giftCardModel.formattedBalance}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <hr />
                      <p className="text-center">Select top up amount:</p>
                      <PublicStorefrontTopupAddToCartComponent
                        model={model}
                        onNavigateToPage={onNavigateToPage}
                        onUpdated={onUpdated}
                        onSelected={showDetailsPageOnClick}
                        giftCardModel={giftCardModel}
                        refreshCount={refreshCount}
                      />
                    </>
                  )}

                  {showPage === "balance" && (
                    <PublicStorefrontCheckBalanceComponent
                      onBack={showCartPageOnClick}
                      model={giftCardModel}
                      settings={settings}
                      giftCardModel={giftCardModel}
                      refreshCount={refreshCount}
                    />
                  )}

                  {showPage === "details" && (
                    <PublicStorefrontTopupGiftCardDetailsComponent
                      model={model}
                      settings={settings}
                      onNavigateToPage={onNavigateToPage}
                      onUpdated={onUpdated}
                      onSubmit={showPaymentPageOnClick}
                      amount={selectedAmount}
                      giftCardModel={giftCardModel}
                      refreshCount={refreshCount}
                    />
                  )}

                  {showPage === "payment" && (
                    <PublicStorefrontPaymentComponent
                      settings={settings}
                      model={model}
                      onNavigateToPage={onNavigateToPage}
                      onUpdated={onUpdated}
                      onSubmit={showPaymentPageOnClick}
                      amount={selectedAmount}
                      giftCardModel={giftCardModel}
                      refreshCount={refreshCount}
                    />
                  )}

                  <div className="card-footer text-center">
                    <a className="btn btn-sm btn-neutral w-full" href={`/`}>
                      Buy more gift cards
                    </a>
                  </div>

                  {showPage !== "balance" &&
                    settings &&
                    !settings.hideCheckBalance && (
                      <div className="card-footer text-center">
                        {!showCheckBalanceForm ? (
                          <a
                            className="text-sm cursor-pointer"
                            onClick={checkBalanceOnClick}
                          >
                            Check your gift card balance
                          </a>
                        ) : (
                          <Form
                            noValidate
                            validated={validated}
                            onSubmit={handleCheckBalanceSubmit}
                          >
                            <InputGroup className="mb-3">
                              <Form.Control
                                required
                                name="checkBalanceCode"
                                value={checkBalanceCode}
                                size="sm"
                                placeholder="Enter gift card code..."
                                aria-label="Enter gift card code..."
                                onChange={handleCheckBalanceCodeChange}
                              />
                              <Button
                                type="submit"
                                //onClick={showBalancePageOnClick}
                                size="sm"
                                variant="primary"
                                className="btn-custom"
                              >
                                Check balance
                              </Button>
                            </InputGroup>
                            {showInvalidCodeError && (
                              <div className="font-semibold text-danger text-sm text-start">
                                No gift card found with the code '
                                {checkBalanceCode}'
                              </div>
                            )}
                          </Form>
                        )}
                      </div>
                    )}
                </div>
              </div>
              {settings && !settings.hideWrappedBranding && (
                <footer className="">
                  <div className="container">
                    <a
                      target="_blank"
                      href="https://wrappedgiftcards.com?utm_source=branding&utm_medium=web"
                    >
                      <div className="row justify-content-center">
                        <div className="col-auto">
                          <img
                            src="/img/logos/wrapped-logo-grey.png"
                            className="h-10"
                            alt="..."
                          />
                        </div>
                      </div>
                      <div className="row justify-content-center text-center">
                        <div className="col-xl-9">
                          <p className="text-sm text-muted opacity-80">
                            Gift cards for omnichannel brands
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </footer>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PublicStorefrontTopupCheckoutPage;
