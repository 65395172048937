import React, { Component } from 'react';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <div className="mt-20 text-center">
                <h4>Wrapped Storefront coming soon.</h4>
            </div>
        );
    }
}
