import React, {
  Component,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import {
  Button,
  Row,
  Modal,
  Form,
  Container,
  Col,
  InputGroup,
  Badge,
  FloatingLabel,
  CloseButton,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  StorefrontApi,
  StorefrontCoupon,
  StorefrontFee,
  StorefrontOrderViewModel,
  StorefrontSettingsViewModel,
} from "../../apiclient";
import { apiConfig } from "../../config";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import "./PublicStorefront.css";

import { useNavigate } from "react-router";
import CurrencyUtil from "../../Helpers/CurrencyUtil";
//import ReactDOM from "react-dom";
//import dayjs from "dayjs";
const dayjs = require("dayjs");
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));

const PublicStorefrontOrderSummaryComponent = (props: any) => {
  const [model, setModel] = useState(props.model as StorefrontOrderViewModel);
  const [couponCode, setCouponCode] = useState("" as string);
  const [validCoupon, setValidCoupon] = useState({} as StorefrontCoupon);
  const [validated, setValidated] = useState(true);
  const [showCouponTextbox, setShowTextbox] = useState(false);
  const [settings, setSettings] = useState({} as StorefrontSettingsViewModel);
  let storefrontApi = new StorefrontApi(
    apiConfig.configuration,
    apiConfig.baseUrl
  );
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  const fetchSettings = () => {
    storefrontApi
      .storefrontSettingsGet()
      .then((response) => {
        var data = response.data;
        if (data) {
          setSettings(data);
        } else {
          // account not found
          navigate("/account-not-found");
        }
      })
      .catch((error: any) => {
        navigate("/account-not-found");
      });
  };

  useEffect(() => {
    if (model) {
      setModel(props.model);
      setSubTotal(model.amount! * model.quantity!);
      fetchSettings();
    }
  }, [props.model]);



  useEffect(() => {
    setTotal(calcTotalAmount());
  }, [validCoupon, subTotal]);

  const calcFeeAmount = (fee: StorefrontFee) => {
    var feeAmount = fee.amount ?? 0;
    if (fee.feeType === "Percent") {
      const percentage = (feeAmount * subTotal) / 100;
      var sfeeAmount = percentage.toFixed(2);
      feeAmount = parseFloat(sfeeAmount);
    }
    return feeAmount;
  };

  const calcDiscountAmount = (coupon: StorefrontCoupon) => {
    if (!coupon) {
      return 0;
    }
    var discountAmount = coupon.amount ?? 0;
    if (coupon.couponType === "Percent") {
      const percentage = (discountAmount * subTotal) / 100;
      var sDiscountAmount = percentage.toFixed(2);
      discountAmount = parseFloat(sDiscountAmount);
    }
    return discountAmount;
  };

  const calcTotalAmount = () => {
    var totalFeeAmount = 0;
    var totalDiscountAmount = calcDiscountAmount(validCoupon);
    var subTotal = model.amount! * model.quantity!;

    if (settings.fees && settings.shouldChargeFees) {
      settings.fees?.map((fee, i) => (totalFeeAmount += calcFeeAmount(fee)));
    }
    return (subTotal - totalDiscountAmount) + totalFeeAmount;
  };

  const onRemoveCouponCode = () => {
    storefrontApi
      .storefrontRemoveCouponGet(model.stripePaymentIntentId!)
      .then((response) => {
        if (response.data) {
          setValidated(true);
        }
      })
      .catch((error: any) => {
        setValidated(false);
        setValidCoupon({});
        console.log("Error", error);
      });
    setModel((values: StorefrontOrderViewModel) => ({
      ...values,
      couponCode: null
    }));

    setCouponCode("");
    setValidCoupon({});
    setValidated(true);
  };

  const handleCouponCodeChange = (e: any) => {
    setCouponCode(e.target.value);
    setValidated(true);
  };

  const handleApplyCouponCode = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    storefrontApi
      .storefrontCouponByCodeGet(model.stripePaymentIntentId!, couponCode)
      .then((response) => {
        var data = response.data;
        if (data) {
          updateOrder(data);
        } else {
          setValidated(false);
          setValidCoupon({});
        }
      })
      .catch((error: any) => {
        setValidated(false);
        setValidCoupon({});
      });
  };

  const updateOrder = (sCoupon: StorefrontCoupon) => {
    storefrontApi
      .storefrontUpdateOrderAndStripeIntentGet(model.stripePaymentIntentId!)
      .then((response) => {
        var data = response.data;
        if (data) {
          setValidCoupon(sCoupon);
          setValidated(true);
          // props.onUpdated(data);
          // //setModel(data);
          setModel((values: StorefrontOrderViewModel) => ({
            ...values,
            couponCode: couponCode
          }));
        } else {
          setValidated(false);
          setValidCoupon({});
        }
      })
      .catch((error: any) => {
        setValidated(false);
        setValidCoupon({});
      });
  };

  return (
    <React.Fragment>
      <div className="col-12">
        <div className="text-center p-5">
          <h4 className="font-semibold mb-1">Order summary</h4>
          <p className="text-sm text-muted">For {settings.companyName}</p>
        </div>
        <div className="">
          <div className="list-group list-group-flush px-5">
            <div className=" list-group-item d-flex align-items-center">
              <div className="flex-fill">
                {model && model.quantity! > 1 ? (
                  <div className="d-block h6 font-semibold mb-1">
                    {model.quantity} x {model.productName}
                  </div>
                ) : (
                  <div className="d-block h6 font-semibold mb-1">
                    {model.productName}
                  </div>
                )}
                {model.audience === "Yourself" ? (
                  <span className="d-block text-sm mb-2">
                    Your name: {model.recipientName}
                  </span>
                ) : (
                  <>
                    <span className="d-block text-sm mb-2">
                      Your name: {model.giftedFromName}
                    </span>
                    <span className="d-block text-sm mb-2">
                      Recipient name: {model.recipientName}
                    </span>
                    <span className="d-block text-sm mb-2">
                      Message: {model.giftMessage}
                    </span>
                  </>
                )}
                <div className="d-flex mx-n1"></div>
              </div>
              <div className="ms-auto text-end">
                <span className="h6 font-semibold mb-1 mr-10">
                  {CurrencyUtil.formatCurrency(subTotal, settings.isoCurrencyCode)}
                </span>
              </div>
            </div>

            <div className=" list-group-item d-flex align-items-center">
              <div className="flex-fill">
                <div className="d-block h6 font-semibold mb-1">Shipping</div>
                {model.audience === "Yourself" ? (
                  <>
                    <span className="d-block text-sm mb-2">
                      By email: {model.recipientEmailAddress}
                    </span>
                  </>
                ) : (
                  <>
                    <span className="d-block text-sm mb-2">
                      By email: {model.recipientEmailAddress}
                    </span>
                    {model.sendLater && (
                      <span className="d-block text-sm mb-2">
                        Send time:{" "}
                        {dayjs(model.giftScheduledFor)
                          .utc()
                          .tz(model.timezone!)
                          .format("YYYY-MM-DD hh:mm a")}
                      </span>
                    )}
                  </>
                )}

                <div className="d-flex mx-n1"></div>
              </div>
              <div className="ms-auto text-end">
                <span className="h6 font-semibold mb-1 mr-10">$0</span>
              </div>
            </div>
            {props.paymentComplete ? (model.couponCode && <div className=" list-group-item d-flex align-items-center">
              <div className="flex-fill">
                <div className="d-block h6 font-bold mb-1">Discount</div>
                <span className="d-block text-sm mb-2">
                  Code: {model.couponCode}
                </span>
                <div className="d-flex mx-n1"></div>
              </div>
              <div className="ms-auto text-end">
                <span className="h6 font-bold mb-1 mr-10">
                  {CurrencyUtil.formatCurrency(model.totalDiscount! * -1, settings.isoCurrencyCode)}
                </span>
              </div>
            </div>) : (settings &&
              settings.shouldAllowCoupons &&
              model.couponCode ?
              (
                <div
                  className=" list-group-item d-flex align-items-center"
                >
                  <div className="flex-fill">
                    <div className="d-block h6 font-bold mb-1">
                      <Badge>
                        {model.couponCode}
                        <CloseButton variant="white" onClick={onRemoveCouponCode} style={{ marginLeft: '5px', padding: "10px" }} />
                      </Badge>
                    </div>
                    <div className="d-flex mx-n1"></div>
                  </div>
                  <div className="ms-auto text-end">
                    <span className="h6 font-semibold mb-1 mr-10">
                      {CurrencyUtil.formatCurrency(calcDiscountAmount(validCoupon) * -1, settings.isoCurrencyCode)}
                    </span>
                  </div>
                </div>
              ) : (
                settings.shouldAllowCoupons &&
                <div className="mt-5">
                  <Form noValidate onSubmit={handleApplyCouponCode}>
                    <Row>
                      <Col xs={12}>
                        <Form.Group>
                          {showCouponTextbox ? (<InputGroup className="mb-3">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Add promotion code"
                            >
                              <Form.Control
                                type="text"
                                placeholder=""
                                value={couponCode}
                                onChange={handleCouponCodeChange}
                                isInvalid={!validated}
                              />
                            </FloatingLabel>

                            {couponCode && (
                              <Button
                                type="submit"
                                variant="primary"
                                className="outline-secondary"
                              >
                                Apply
                              </Button>
                            )}
                          </InputGroup>) : (<>
                            <Button className="pl-0" onClick={() => setShowTextbox(true)} variant="link">Add promotion code</Button>
                          </>)}

                          {!validated &&
                            <div className="text-danger text-sm">
                              This code is invalid.
                            </div>}
                        </Form.Group>

                      </Col>
                    </Row>
                  </Form>
                </div>
              ))}

            {settings &&
              settings.shouldChargeFees &&
              settings.fees &&
              settings.fees?.map((fee, i) => (
                <div
                  key={"fee-small" + i}
                  className=" list-group-item d-flex align-items-center"
                >
                  <div className="flex-fill">
                    <div className="d-block h6 font-bold mb-1">{fee.name}</div>
                    <div className="d-flex mx-n1"></div>
                  </div>
                  <div className="ms-auto text-end">
                    <span className="h6 font-semibold mb-1 mr-10">
                      {CurrencyUtil.formatCurrency(calcFeeAmount(fee), settings.isoCurrencyCode)}
                    </span>
                  </div>
                </div>
              ))}
            <div className=" list-group-item d-flex align-items-center">
              <div className="flex-fill">
                <div className="d-block h6 font-bold mb-1">Total</div>
                <div className="d-flex mx-n1"></div>
              </div>
              <div className="ms-auto text-end">
                <span className="h6 font-bold mb-1 mr-10">
                  {CurrencyUtil.formatCurrency(model.paymentAmount ?? total, settings.isoCurrencyCode)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PublicStorefrontOrderSummaryComponent;
