import axios from "axios";
import { Configuration } from "./apiclient";
//import { Provider, useAppBridge } from '@shopify/app-bridge-react';
//import { getSessionToken } from "@shopify/app-bridge/utilities";
//import { AppBridgeState, ClientApplication } from "@shopify/app-bridge";

// axios.interceptors.response.use(
//     (response) => response,
//     (error) => {
//         if (error.status === 401 || error.response.status === 401 || error.status === 403 || error.response.status === 403) {
//             window.location.href = "/account/login"
//         }
//         return Promise.reject(
//             error || "Something went wrong"
//         )
//     }
// );


export const apiConfig = {
    baseUrl: window.location.protocol,
    configuration: new Configuration(),
};

//export const shopifyAppBridge = {
//    app: {} as ClientApplication<AppBridgeState>,
//    host: '' as string
//}
