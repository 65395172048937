import React, {
  Component,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import {
  Button,
  Row,
  Modal,
  Form,
  Container,
  Col,
  InputGroup,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  StorefrontApi,
  StorefrontOrderViewModel,
  StorefrontSettingsViewModel,
} from "../../../apiclient";
import { apiConfig } from "../../../config";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import "react-quill/dist/quill.snow.css";
import "../PublicStorefront.css";
import ReactDOM from "react-dom";
import { Elements, PaymentElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeCheckoutForm from "../StripeCheckoutForm";
import dayjs from "dayjs";
import PublicStorefrontOrderSummaryComponent from "./PublicStorefrontTopupOrderSummaryComponent";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe(
//   "pk_test_51NFU7OKbUkDc7DbQRwU7doGMiWk80A1g5t63WEQWfi179dGceLotM9LTNxbuUHLZtD9FX2wjgPmJzNWgViM8fuS100MyOns4Lh"
// );

const PublicStorefrontTopupPaymentComponent = (props: any) => {
  const [clientSecret, setClientSecret] = useState("");
  const [model, setModel] = useState(props.model as StorefrontOrderViewModel);
  const [settings, setSettings] = useState(props.settings as StorefrontSettingsViewModel);
  const [formattedTotal, setFormattedTotal] = useState("");
  const [orderTotal, setOrderTotal] = useState(0);
 // let { id } = useParams();

  let storefrontApi = new StorefrontApi(
    apiConfig.configuration,
    apiConfig.baseUrl
  );

  useEffect(() => {
    fetchPaymentIntentSecret();
    if (model) {
      setOrderTotal(model.amount! * model.quantity!);
    }
  }, []);

  const fetchPaymentIntentSecret = () => {
    storefrontApi
      .storefrontCreateOrderAndStripeIntentPost("1", model)
      .then((response) => {
        var orderData = response.data;
        setClientSecret(orderData.stripePaymentIntentSecret!);
        props.onUpdated(orderData);
      })
      .catch((error: any) => {});
  };

  const onNavigateToPage = (pageName: string) => {
    props.onNavigateToPage(pageName);
  };

  return (
    <React.Fragment>
      <div className="">
        <div className="col-md-12 p-5">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a
                  className="cursor-pointer"
                  onClick={() => onNavigateToPage("cart")}
                >
                  Cart
                </a>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <a
                  className="cursor-pointer"
                  onClick={() => onNavigateToPage("details")}
                >
                  Information
                </a>
              </li>
              <li
                className="breadcrumb-item active font-bold"
                aria-current="page"
              >
                Payment
              </li>
            </ol>
          </nav>
        </div>

        <div className="col-12">
          <PublicStorefrontOrderSummaryComponent model={model} giftCardModel={props.giftCardModel} paymentComplete={false} />
          <div className="list-group list-group-flush p-5 pt-0">
            <div className="col-md-12">
              {clientSecret && settings && settings.stripeKey && (
                <Elements
                  stripe={loadStripe(
                    settings.stripeKey!,
                     {
                      stripeAccount: settings.stripeConnectAccountId,
                    }
                  )}
                  options={{
                    // passing the client secret obtained in step 3
                    clientSecret: clientSecret,
                    // Fully customizable with appearance API.
                    //appearance: {/*...*/},
                  }}
                >
                  <StripeCheckoutForm settings={settings} paymentType="topup" topupGiftCardId={model.topupGiftCardId} />
                </Elements>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PublicStorefrontTopupPaymentComponent;
