import React, {
  Component,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import {
  Button,
  Row,
  Modal,
  Form,
  Container,
  Col,
  InputGroup,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  StorefrontApi,
  StorefrontFee,
  StorefrontOrderViewModel,
  StorefrontSettingsViewModel,
  GiftCardDetailViewModel
} from "../../../apiclient";
import { apiConfig } from "../../../config";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import "../PublicStorefront.css";
import { useNavigate } from "react-router";
import CurrencyUtil from "../../../Helpers/CurrencyUtil";
//import ReactDOM from "react-dom";
//import dayjs from "dayjs";
const dayjs = require("dayjs");
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));

const PublicStorefrontTopupOrderSummaryComponent = (props: any) => {
  const [model, setModel] = useState(props.model as StorefrontOrderViewModel);
  const [settings, setSettings] = useState({} as StorefrontSettingsViewModel);
  const [giftCardModel, setGiftCardModel] = useState(props.giftCardModel as GiftCardDetailViewModel);
  let storefrontApi = new StorefrontApi(
    apiConfig.configuration,
    apiConfig.baseUrl
  );
  const [subTotal, setSubTotal] = useState(0);
  const navigate = useNavigate();

  const fetchSettings = () => {
    storefrontApi
      .storefrontSettingsGet()
      .then((response) => {
        var data = response.data;
        if (data) {
          setSettings(data);
        } else {
          // account not found
          navigate("/account-not-found");
        }
      })
      .catch((error: any) => {
        console.log("get error", error);
        navigate("/account-not-found");
      });
  };

  useEffect(() => {
    if (model) {
      setSubTotal(model.amount! * model.quantity!);
      fetchSettings();
    }
  }, []);

  const calcFeeAmount = (fee: StorefrontFee) => {
    var feeAmount = fee.amount ?? 0;
    if (fee.feeType === "Percent") {
      const percentage = (feeAmount * subTotal) / 100;
      var sfeeAmount = percentage.toFixed(2);
      feeAmount = parseFloat(sfeeAmount);
    }
    return feeAmount;
  };

  const calcTotalAmount = () => {
    var totalFeeAmount = 0;
    var subTotal = model.amount! * model.quantity!;
    if (settings.fees && settings.shouldChargeFees) {
      settings.fees?.map((fee, i) => (totalFeeAmount += calcFeeAmount(fee)));
    }
    return subTotal + totalFeeAmount;
  };

  return (
    <React.Fragment>
      <div className="col-12">
        <div className="text-center p-5">
          <h4 className="font-semibold mb-1">Order summary</h4>
          <p className="text-sm text-muted">For use at {settings.companyName}</p>
        </div>
        <div className="">
          <div className="list-group list-group-flush px-5">
            <div className=" list-group-item d-flex align-items-center">
              <div className="flex-fill">
                {model && model.quantity! > 1 ? (
                  <div className="d-block h6 font-semibold mb-1">
                    {model.quantity} x {model.productName}
                  </div>
                ) : (
                  <>
                  <div className="d-block h6 font-semibold mb-1">
                    Top up
                  </div>
                  {giftCardModel && <p>Gift card: {giftCardModel.code}</p>}
                  </>
                )}
                {/* {model.audience === "Yourself" ? (
                  <span className="d-block text-sm mb-2">
                    Your name: {model.recipientName}
                  </span>
                ) : (
                  <>
                    <span className="d-block text-sm mb-2">
                      Your name: {model.giftedFromName}
                    </span>
                    <span className="d-block text-sm mb-2">
                      Recipient name: {model.recipientName}
                    </span>
                    <span className="d-block text-sm mb-2">
                      Message: {model.giftMessage}
                    </span>
                  </>
                )} */}
                <div className="d-flex mx-n1"></div>
              </div>
              <div className="ms-auto text-end">
                <span className="h6 font-semibold mb-1 mr-10">
                  {CurrencyUtil.formatCurrency(subTotal, settings.isoCurrencyCode)}
                </span>
              </div>
            </div>

            {/* <div className=" list-group-item d-flex align-items-center">
              <div className="flex-fill">
                <div className="d-block h6 font-semibold mb-1">Shipping</div>
                {model.audience === "Yourself" ? (
                  <>
                    <span className="d-block text-sm mb-2">
                      By email: {model.recipientEmailAddress}
                    </span>
                  </>
                ) : (
                  <>
                    <span className="d-block text-sm mb-2">
                      By email: {model.recipientEmailAddress}
                    </span>
                    {model.sendLater && (
                      <span className="d-block text-sm mb-2">
                        Send time:{" "}
                        {dayjs(model.giftScheduledFor)
                          .utc()
                          .tz(model.timezone!)
                          .format("YYYY-MM-DD hh:mm a")}
                      </span>
                    )}
                  </>
                )}

                <div className="d-flex mx-n1"></div>
              </div>
              <div className="ms-auto text-end">
                <span className="h6 font-semibold mb-1 mr-10">$0</span>
              </div>
            </div> */}
            {settings &&
              settings.shouldChargeFees &&
              settings.fees &&
              settings.fees?.map((fee, i) => (
                <div
                  key={"fee-small" + i}
                  className=" list-group-item d-flex align-items-center"
                >
                  <div className="flex-fill">
                    <div className="d-block h6 font-bold mb-1">{fee.name}</div>
                    <div className="d-flex mx-n1"></div>
                  </div>
                  <div className="ms-auto text-end">
                    <span className="h6 font-semibold mb-1 mr-10">
                      {CurrencyUtil.formatCurrency(calcFeeAmount(fee), settings.isoCurrencyCode)}
                    </span>
                  </div>
                </div>
              ))}
            <div className=" list-group-item d-flex align-items-center">
              <div className="flex-fill">
                <div className="d-block h6 font-bold mb-1">Total</div>
                <div className="d-flex mx-n1"></div>
              </div>
              <div className="ms-auto text-end">
                <span className="h6 font-semibold mb-1 mr-10 text-primary">
                  {CurrencyUtil.formatCurrency(calcTotalAmount(), settings.isoCurrencyCode)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PublicStorefrontTopupOrderSummaryComponent;
