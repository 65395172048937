import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import { BlankLayout } from "./layouts/BlankLayout";
import PublicStorefrontCheckoutPage from "./pages/storefront/PublicStorefrontCheckoutPage";
import PublicStorefrontPaymentCompletePage from "./pages/storefront/PublicStorefrontPaymentCompletePage";
import PublicStorefrontTopupCheckoutPage from "./pages/storefront/topup/PublicStorefrontTopupCheckoutPage";
import PublicStorefrontTopupPaymentCompletePage from "./pages/storefront/topup/PublicStorefrontTopupPaymentCompletePage";

import { Home } from "./components/Home";
import { AccountNotFound } from "./components/AccountNotFound";
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import NotFoundPage from './pages/error/NotFoundPage';

//export default class App extends Component {
//  static displayName = App.name;

//  render() {
//    return (
//      <Layout>
//        <Routes>
//          {AppRoutes.map((route, index) => {
//            const { element, ...rest } = route;
//            return <Route key={index} {...rest} element={element} />;
//          })}
//        </Routes>
//      </Layout>
//    );
//  }
//}


const routesConfig = [
    {
        element: <BlankLayout />,
        children: [
            {
                path: "/",
                element: <PublicStorefrontCheckoutPage />,
            },
            {
                path: "/account-not-found",
                element: <NotFoundPage />
            },
            {
                path: "/checkout/complete",
                element: <PublicStorefrontPaymentCompletePage />,
            },
            {
                path: "/checkout",
                element: <PublicStorefrontCheckoutPage />,
            },
            {
                path: "/public/storefront/checkout",
                element: <PublicStorefrontCheckoutPage />,
            },
            {
                path: "/public/storefront/complete",
                element: <PublicStorefrontPaymentCompletePage />,
            },
        
            {
                path: "/topup/checkout/complete/:id",
                element: <PublicStorefrontTopupPaymentCompletePage />,
            },
            {
                path: "/topup/checkout/:id",
                element: <PublicStorefrontTopupCheckoutPage />,
            },
            {
                path: "/public/storefront/topup/checkout/:id",
                element: <PublicStorefrontTopupCheckoutPage />,
            },
            {
                path: "/public/storefront/topup/complete/:id",
                element: <PublicStorefrontTopupPaymentCompletePage />,
            }
        ]
    },
    {
        path: "*",
        element: <NotFoundPage />,
    },];

const router = createBrowserRouter(routesConfig);
const App = () => {

    return <><RouterProvider router={router} /></>;

};

export default App;