import React, {
  Component,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import {
  Button,
  Row,
  Modal,
  Form,
  Container,
  Col,
  InputGroup,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  StorefrontApi,
  GiftCardDetailViewModel,
  StorefrontSettingsViewModel,
} from "../../apiclient";
import { apiConfig } from "../../config";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import "react-quill/dist/quill.snow.css";
import "./PublicStorefront.css";
import { useNavigate } from "react-router";
const dayjs = require("dayjs");
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));
dayjs.extend(require("dayjs/plugin/relativeTime"));

//import "./assets.css";
const PublicStorefrontCheckBalanceComponent = (props: any) => {
  const [model, setModel] = useState(props.model as GiftCardDetailViewModel);
  const [settings, setSettings] = useState(
    props.settings as StorefrontSettingsViewModel
  );
  const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const navigate = useNavigate();
  //let { id } = useParams();

  const backOnClick = () => {
    props.onBack();
  };

  const navigateToTopup = () => {
    navigate(`/topup/checkout/${model.id}`);
  };

  return (
    <React.Fragment>
      {model && (
        <div className="row g-5">
          <div className="text-center p-5">
            <h4 className="font-semibold mb-1">Gift card balance</h4>
            <p className="text-sm text-muted">For {settings.companyName}</p>
          </div>
          <div className="col-12">
            <div className="d-flex mt-7">
              <div className="col-6 text-center">
                <span className="d-block text-sm">Code</span>
                <div className="h4 font-bolder">{model.code}</div>
              </div>
              <div className="col-6 text-center">
                <span className="d-block text-sm">Remaining balance</span>
                <div className="h4 font-bolder">{model.formattedBalance}</div>
              </div>
            </div>
            {model.expiresOn && (
              <>
                <div className="d-flex mt-3">
                  <div className="offset-6 col-6 text-center">
                    <span className="d-block text-sm">Expires</span>
                    <div className="h4 font-bolder">
                      {model.expiresOn &&
                        dayjs(model.expiresOn)
                          .utc()
                          .tz(clientTimezone)
                          .format("DD MMM YYYY")}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="col-md-12 p-5">
            {settings.shouldAllowTopups && (
              <button
                onClick={navigateToTopup}
                type="button"
                className="btn btn-neutral w-full mb-2"
              >
                Add funds / Top up
              </button>
            )}
            <button
              onClick={backOnClick}
              type="button"
              className="btn btn-primary w-full btn-custom"
            >
              <i className="bi bi-arrow-left mr-10" /> Back
            </button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default PublicStorefrontCheckBalanceComponent;
