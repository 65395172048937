import React, { useState, useEffect, useRef } from "react";
import VideoRecorder from "react-video-recorder";

const VideoRecorderWithCountdown = (props) => {
  const videoRecorderRef = useRef();
  const mimeType = (/Mobi|Android/i.test(navigator.userAgent)) ? "video/mp4" : "video/webm";

    return (
    <VideoRecorder
     ref={videoRecorderRef}
     mimeType={mimeType}
     countdownTime={3000}
     dataAvailableTimeout={2000}
     timeLimit={60000}
     isCameraOn={true}npm 
     showReplayControls={true}
     replayVideoAutoplayAndLoopOff={true}
     useVideoInput={props.useVideoInput}
     isOnInitially={true}
     onRecordingComplete={(video, startedAt, thumbnail, duration, extension) => {
       var upload = {
         video: video,
         startedAt: startedAt,
         thumbnail: thumbnail,
         duration:duration
       };

       props.videoUploadCompleted(upload);
     }}
    />
  );
};

export default VideoRecorderWithCountdown;
