import React, {
  Component,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import {
  Button,
  Row,
  Modal,
  Form,
  Container,
  Col,
  InputGroup,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  Audience,
  GiftCardImageAssetViewModel,
  GiftToFriendPageSettingsViewModel,
  StorefrontApi,
  StorefrontOrderViewModel,
  StorefrontSettingsViewModel,
  UploadGiftCardsVideoMessageResultPayload,
} from "../../apiclient";
import { apiConfig } from "../../config";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import "react-quill/dist/quill.snow.css";
import "./PublicStorefront.css";
import TimezoneSelect from "react-timezone-select";
import StorefrontVideoRecorderModal from "./StorefrontVideoRecorderModal";

//import "./assets.css";
const PublicStorefrontGiftCardDetailsComponent = (props: any) => {
  const [audience, setAudience] = useState("SomeoneElse");
  const [sendOption, setSendOption] = useState("now");
  const [model, setModel] = useState(props.model as StorefrontOrderViewModel);
  const [settings, setSettings] = useState(
    props.settings as StorefrontSettingsViewModel
  );

  const [themeModel, setThemeModel] = useState(
    {} as GiftToFriendPageSettingsViewModel
  );
  const [images, setImages] = useState([] as GiftCardImageAssetViewModel[]);
  const [validated, setValidated] = useState(false);
  const [isLoading, setLoading] = useState(false as boolean);
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [selectedImage, setSelectedImage] = useState(
    {} as GiftCardImageAssetViewModel
  );

  let storefrontApi = new StorefrontApi(
    apiConfig.configuration,
    apiConfig.baseUrl
  );

  const fetchThemeData = () => {
    storefrontApi
      .storefrontGiftToFriendPagePublicGet()
      .then((response) => {
        var data = response.data;
        //if(data.useLegacy){
        //  window.location.href = `/public/gift-to-friend-legacy/${id}`;
        //}
        setThemeModel(data);
      })
      .catch((error: any) => {});
  };
  const [showModal, setShowModal] = useState(false);
  //let { id } = useParams();

  useEffect(() => {
    if (images.length > 0) {
      setSelectedImage(images[0]);
      setModel((values: StorefrontOrderViewModel) => ({
        ...values,
        giftImageUrl: images[0].imageUrl,
      }));
    }
  }, [images]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const fetchAssetImages = () => {
    storefrontApi
      .storefrontActiveImageAssetsGet()
      .then((response) => {
        var data = response.data;

        setImages(data);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };

  const uploadComplete = (upload: UploadGiftCardsVideoMessageResultPayload) => {
    if (upload) {
      setModel((values: StorefrontOrderViewModel) => ({
        ...values,
        videoMessageUrl: upload.videoMessageUrl,
        videoMessageThumbnailUrl: upload.videoMessageThumbnailUrl,
      }));
    }
  };

  const deleteVideoMessage = () => {
    storefrontApi
      .storefrontRemoveVideoMessageGet()
      .then((response) => {
        var data = response.data;
        if (data) {
          setModel((values: StorefrontOrderViewModel) => ({
            ...values,
            videoMessageUrl: "",
            videoMessageThumbnailUrl: "",
          }));
        }
      })
      .catch((error: any) => {});
  };

  const getLocalDate = (dateStr: string) => {
    const currentDateTime = new Date(dateStr);
    const year = currentDateTime.getFullYear();
    const month = String(currentDateTime.getMonth() + 1).padStart(2, "0");
    const day = String(currentDateTime.getDate()).padStart(2, "0");
    const hours = String(currentDateTime.getHours()).padStart(2, "0");
    const minutes = String(currentDateTime.getMinutes()).padStart(2, "0");

    var localDate = `${year}-${month}-${day}T${hours}:${minutes}`;
    return localDate;
  };

  useEffect(() => {
    if (model) {
      props.onUpdated(model);
    }
  }, [model]);

  useEffect(() => {
    if (model) {
      setModel((values: StorefrontOrderViewModel) => ({
        ...values,
        giftScheduledFor: getLocalDate(new Date().toString()),
        quantity: 1,
        accountId: model.accountId,
        currency: model.currency ?? "nzd",
        audience: model.audience ?? "SomeoneElse",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }));

      fetchAssetImages();
      fetchThemeData();
    }
  }, []);

  const timezoneOnChange = (selectedTimezone: any) => {
    setTimezone(selectedTimezone.value);
  };

  const handleAudienceChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setAudience(value);
    setModel((values: StorefrontOrderViewModel) => ({
      ...values,
      audience: value,
    }));
  };

  const handleSendOptionChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setSendOption(value);

    setModel((values: StorefrontOrderViewModel) => ({
      ...values,
      sendLater: value === "later",
    }));
  };

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (form.checkValidity() === true) {
      props.onSubmit();
    }
  };

  const onNavigateToPage = (pageName: string) => {
    props.onNavigateToPage(pageName);
  };

  const handleChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setModel((values: StorefrontOrderViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  const handleImageSelect = (image: GiftCardImageAssetViewModel) => {
    setSelectedImage(image);
    setModel((values: StorefrontOrderViewModel) => ({
      ...values,
      giftImageUrl: image.imageUrl,
    }));
  };

  return (
    <React.Fragment>
      {model && (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="row g-5 p-5">
            <div className="col-md-12 p-5 pb-0 pt-0">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a
                      className="cursor-pointer"
                      onClick={() => onNavigateToPage("cart")}
                    >
                      Cart
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item active font-bold"
                    aria-current="page"
                  >
                    Information
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    Payment
                  </li>
                </ol>
              </nav>
            </div>
            <div className="text-center">
              {model.quantity! > 1 ? (
                <h4 className="font-semibold mb-1">
                  {model.quantity} x {model.productName}
                </h4>
              ) : (
                <h4 className="font-semibold mb-1">{model.productName}</h4>
              )}

              <p className="text-sm text-muted">
                For use at {settings.companyName}
              </p>
            </div>
            <div className="col-md-12">
              <ButtonGroup className="w-full">
                <ToggleButton
                  size="sm"
                  key={"someoneElse"}
                  id={`radio-someoneElse`}
                  type="radio"
                  variant={"outline-primary"}
                  name="radio"
                  value={Audience.SomeoneElse}
                  checked={audience === Audience.SomeoneElse}
                  onChange={handleAudienceChange}
                >
                  <i className="bi bi-gift-fill mr-5" /> Someone else
                </ToggleButton>
                <ToggleButton
                  size="sm"
                  key={"yourself"}
                  id={`radio-yourself`}
                  type="radio"
                  variant={"outline-primary"}
                  name="radio"
                  value={Audience.Yourself}
                  checked={audience === Audience.Yourself}
                  onChange={handleAudienceChange}
                >
                  <i className="bi bi-heart mr-5" /> Yourself
                </ToggleButton>
              </ButtonGroup>
            </div>
            {audience === Audience.SomeoneElse ? (
              <>
                <div className="col-md-12">
                  {images.length > 1 && (
                    <div className="">
                      <div className="form-label">Gift card design</div>
                      <Row>
                        {images.map((image) => (
                          <Col key={image.id} xs={6} sm={6} md={4}>
                            <label
                              className={`radio-image ${
                                selectedImage.id === image.id ? "selected" : ""
                              }`}
                              onClick={() => handleImageSelect(image)}
                            >
                              <img
                                src={image.imageUrl}
                                alt={`Image ${image.id}`}
                              />
                            </label>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  )}
                </div>
                <div className="col-md-12">
                  <div className="">
                    <label className="form-label" htmlFor="quantity">
                      Quantity
                    </label>
                    <Form.Select
                      aria-label="quantity"
                      name="quantity"
                      value={model?.quantity ?? "1"}
                      onChange={handleChange}
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </Form.Select>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="">
                    <label className="form-label" htmlFor="giftedFromName">
                      Your name
                    </label>
                    <Form.Control
                      required
                      type="text"
                      name="giftedFromName"
                      value={model.giftedFromName!}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="">
                    <label className="form-label" htmlFor="giftedFromEmail">
                      Your email address (for the receipt)
                    </label>
                    <Form.Control
                      required
                      type="text"
                      name="giftedFromEmail"
                      value={model.giftedFromEmail!}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="">
                    <label className="form-label" htmlFor="recipientName">
                      Recipient name
                    </label>
                    <Form.Control
                      required
                      type="text"
                      name="recipientName"
                      value={model.recipientName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="">
                    <label className="form-label" htmlFor="giftMessage">
                      Message
                    </label>
                    <Form.Control
                      name={"giftMessage"}
                      required
                      className="form-control"
                      value={model.giftMessage!}
                      onChange={handleChange}
                      as="textarea"
                      rows={3}
                    />
                  </div>
                </div>
                {themeModel.isVideoMessageEnabled &&
                <div className="col-md-12">
                  <div className="">
                    <>
                      {!model.videoMessageUrl ? (
                        <div className="mt-5">
                          <button
                            onClick={openModal}
                            type="button"
                            className="btn btn-secondary w-full"
                          >
                            <span className="bi-camera-video mr-10"></span>
                            Record a video message (optional)
                          </button>
                        </div>
                      ) : (
                        <div className="mt-5">
                          <button
                            onClick={openModal}
                            type="button"
                            className="btn btn-secondary w-full"
                          >
                            <span className="bi-camera-video mr-10"></span>
                            Edit video message
                          </button>
                          <button
                            onClick={deleteVideoMessage}
                            type="button"
                            className="btn btn-danger w-full mt-5"
                          >
                            <span className="bi-trash mr-10"></span>
                            Remove video
                          </button>
                        </div>
                      )}
                    </>
                  </div>
                </div>}
                <div className="col-md-12">
                  <div className="">
                    <label
                      className="form-label"
                      htmlFor="recipientEmailAddress"
                    >
                      What email address should we send the gift card to?
                    </label>

                    <InputGroup className="mb-3">
                      <Form.Control
                        required
                        type="text"
                        name="recipientEmailAddress"
                        value={model.recipientEmailAddress}
                        onChange={handleChange}
                      />
                      <Form.Select
                        style={{ maxWidth: "180px" }}
                        aria-label="sendOption"
                        name="sendOption"
                        value={sendOption ?? "now"}
                        onChange={handleSendOptionChange}
                      >
                        <option value="now">Send now</option>
                        <option value="later">Send later</option>
                      </Form.Select>
                    </InputGroup>
                    {model.sendLater && (
                      <>
                        <Form.Group className="mt-5">
                          <Form.Label>Sent at</Form.Label>
                          <Form.Control
                            name="giftScheduledFor"
                            type="datetime-local"
                            value={model.giftScheduledFor || ""}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>
                        <Form.Group className="mt-5">
                          <Form.Label>Timezone</Form.Label>
                          <TimezoneSelect
                            value={timezone}
                            onChange={timezoneOnChange}
                          />
                        </Form.Group>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <button type="submit" className="btn btn-primary w-full btn-custom">
                    Continue <i className="ml-10 bi bi-arrow-right" />
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="col-md-12">
                  <div className="">
                    <label className="form-label" htmlFor="quantity">
                      Quantity
                    </label>
                    <Form.Select
                      aria-label="quantity"
                      name="quantity"
                      value={model?.quantity ?? "1"}
                      onChange={handleChange}
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </Form.Select>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="">
                    <label className="form-label" htmlFor="recipientName">
                      Your name
                    </label>
                    <Form.Control
                      required
                      type="text"
                      name="recipientName"
                      value={model.recipientName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="">
                    <label className="form-label" htmlFor="formInputExample">
                      What email address should we send the gift card to?
                    </label>
                    <Form.Control
                      required
                      type="text"
                      name="recipientEmailAddress"
                      value={model.recipientEmailAddress}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <button type="submit" className="btn btn-primary w-full btn-custom">
                    Continue <i className="ml-10 bi bi-arrow-right" />
                  </button>
                </div>
              </>
            )}
          </div>
        </Form>
      )}
      <StorefrontVideoRecorderModal
        translation={themeModel}
        showModal={showModal}
        onClose={closeModal}
        uploadComplete={uploadComplete}
      />
    </React.Fragment>
  );
};

export default PublicStorefrontGiftCardDetailsComponent;
