/* tslint:disable */
/* eslint-disable */
/**
 * GiftKard API
 * An API for GiftKard Platform.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: hello@wrappedgiftcards.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CheckBalancePageSettingsViewModel } from '../models';
// @ts-ignore
import { GiftCardDetailViewModel } from '../models';
// @ts-ignore
import { GiftCardImageAssetViewModel } from '../models';
// @ts-ignore
import { GiftToFriendPageSettingsViewModel } from '../models';
// @ts-ignore
import { StorefrontCoupon } from '../models';
// @ts-ignore
import { StorefrontOrderViewModel } from '../models';
// @ts-ignore
import { StorefrontSettingsViewModel } from '../models';
// @ts-ignore
import { UploadGiftCardsVideoMessageResultPayload } from '../models';
/**
 * StorefrontApi - axios parameter creator
 * @export
 */
export const StorefrontApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontActiveImageAssetsGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Storefront/ActiveImageAssets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontCheckBalancePagePublicGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Storefront/CheckBalancePagePublic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontCheckbalancestyleCssGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Storefront/checkbalancestyle.css`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [paymentIntentId] 
         * @param {string} [code] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontCouponByCodeGet: async (paymentIntentId?: string, code?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Storefront/CouponByCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (paymentIntentId !== undefined) {
                localVarQueryParameter['paymentIntentId'] = paymentIntentId;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {StorefrontOrderViewModel} [storefrontOrderViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontCreateOrderAndStripeIntentPost: async (apiVersion?: string, storefrontOrderViewModel?: StorefrontOrderViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Storefront/CreateOrderAndStripeIntent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storefrontOrderViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontGetByCodeCodeGet: async (code: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('storefrontGetByCodeCodeGet', 'code', code)
            const localVarPath = `/Storefront/GetByCode/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontGetByIdIdGet: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storefrontGetByIdIdGet', 'id', id)
            const localVarPath = `/Storefront/GetById/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontGetCustomEmbedScriptGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Storefront/GetCustomEmbedScript`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontGetOrProcessOrderGet: async (id?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Storefront/GetOrProcessOrder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontGiftToFriendPagePublicGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Storefront/GiftToFriendPagePublic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontGifttofriendstyleCssGet: async (accountId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Storefront/gifttofriendstyle.css`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontInitStorefrontOrderPost: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Storefront/InitStorefrontOrder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} giftCardToTopupId 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontInitStorefrontTopupOrderGiftCardToTopupIdPost: async (giftCardToTopupId: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'giftCardToTopupId' is not null or undefined
            assertParamExists('storefrontInitStorefrontTopupOrderGiftCardToTopupIdPost', 'giftCardToTopupId', giftCardToTopupId)
            const localVarPath = `/Storefront/InitStorefrontTopupOrder/{giftCardToTopupId}`
                .replace(`{${"giftCardToTopupId"}}`, encodeURIComponent(String(giftCardToTopupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontPaymentIntentStatusGet: async (id?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Storefront/paymentIntentStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [paymentIntentId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontRemoveCouponGet: async (paymentIntentId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Storefront/RemoveCoupon`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (paymentIntentId !== undefined) {
                localVarQueryParameter['paymentIntentId'] = paymentIntentId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [videoMessageUrl] 
         * @param {string} [videoMessageThumbnailUrl] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontRemoveVideoMessageGet: async (videoMessageUrl?: string, videoMessageThumbnailUrl?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Storefront/RemoveVideoMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (videoMessageUrl !== undefined) {
                localVarQueryParameter['videoMessageUrl'] = videoMessageUrl;
            }

            if (videoMessageThumbnailUrl !== undefined) {
                localVarQueryParameter['videoMessageThumbnailUrl'] = videoMessageThumbnailUrl;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontSettingsGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Storefront/Settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontStorefrontOrderGet: async (id?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Storefront/StorefrontOrder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [paymentIntentId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontUpdateOrderAndStripeIntentGet: async (paymentIntentId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Storefront/UpdateOrderAndStripeIntent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (paymentIntentId !== undefined) {
                localVarQueryParameter['paymentIntentId'] = paymentIntentId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {string} [giftCardId] 
         * @param {any} [videoMessageFile] 
         * @param {any} [videoMessageThumbnailFile] 
         * @param {number} [videoMessageSize] 
         * @param {number} [videoMessageThumbnailSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontUploadGiftCardVideoMessagePost: async (apiVersion?: string, giftCardId?: string, videoMessageFile?: any, videoMessageThumbnailFile?: any, videoMessageSize?: number, videoMessageThumbnailSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Storefront/UploadGiftCardVideoMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


            if (giftCardId !== undefined) { 
                localVarFormParams.append('giftCardId', giftCardId as any);
            }
    
            if (videoMessageFile !== undefined) { 
                localVarFormParams.append('videoMessageFile', videoMessageFile as any);
            }
    
            if (videoMessageThumbnailFile !== undefined) { 
                localVarFormParams.append('videoMessageThumbnailFile', videoMessageThumbnailFile as any);
            }
    
            if (videoMessageSize !== undefined) { 
                localVarFormParams.append('videoMessageSize', videoMessageSize as any);
            }
    
            if (videoMessageThumbnailSize !== undefined) { 
                localVarFormParams.append('videoMessageThumbnailSize', videoMessageThumbnailSize as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StorefrontApi - functional programming interface
 * @export
 */
export const StorefrontApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StorefrontApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storefrontActiveImageAssetsGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GiftCardImageAssetViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storefrontActiveImageAssetsGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storefrontCheckBalancePagePublicGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckBalancePageSettingsViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storefrontCheckBalancePagePublicGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storefrontCheckbalancestyleCssGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storefrontCheckbalancestyleCssGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [paymentIntentId] 
         * @param {string} [code] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storefrontCouponByCodeGet(paymentIntentId?: string, code?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorefrontCoupon>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storefrontCouponByCodeGet(paymentIntentId, code, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {StorefrontOrderViewModel} [storefrontOrderViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storefrontCreateOrderAndStripeIntentPost(apiVersion?: string, storefrontOrderViewModel?: StorefrontOrderViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorefrontOrderViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storefrontCreateOrderAndStripeIntentPost(apiVersion, storefrontOrderViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} code 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storefrontGetByCodeCodeGet(code: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftCardDetailViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storefrontGetByCodeCodeGet(code, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storefrontGetByIdIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftCardDetailViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storefrontGetByIdIdGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storefrontGetCustomEmbedScriptGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storefrontGetCustomEmbedScriptGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storefrontGetOrProcessOrderGet(id?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorefrontOrderViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storefrontGetOrProcessOrderGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storefrontGiftToFriendPagePublicGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftToFriendPageSettingsViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storefrontGiftToFriendPagePublicGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storefrontGifttofriendstyleCssGet(accountId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storefrontGifttofriendstyleCssGet(accountId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storefrontInitStorefrontOrderPost(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorefrontOrderViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storefrontInitStorefrontOrderPost(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} giftCardToTopupId 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storefrontInitStorefrontTopupOrderGiftCardToTopupIdPost(giftCardToTopupId: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorefrontOrderViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storefrontInitStorefrontTopupOrderGiftCardToTopupIdPost(giftCardToTopupId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storefrontPaymentIntentStatusGet(id?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storefrontPaymentIntentStatusGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [paymentIntentId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storefrontRemoveCouponGet(paymentIntentId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorefrontOrderViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storefrontRemoveCouponGet(paymentIntentId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [videoMessageUrl] 
         * @param {string} [videoMessageThumbnailUrl] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storefrontRemoveVideoMessageGet(videoMessageUrl?: string, videoMessageThumbnailUrl?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storefrontRemoveVideoMessageGet(videoMessageUrl, videoMessageThumbnailUrl, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storefrontSettingsGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorefrontSettingsViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storefrontSettingsGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storefrontStorefrontOrderGet(id?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorefrontOrderViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storefrontStorefrontOrderGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [paymentIntentId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storefrontUpdateOrderAndStripeIntentGet(paymentIntentId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorefrontOrderViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storefrontUpdateOrderAndStripeIntentGet(paymentIntentId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {string} [giftCardId] 
         * @param {any} [videoMessageFile] 
         * @param {any} [videoMessageThumbnailFile] 
         * @param {number} [videoMessageSize] 
         * @param {number} [videoMessageThumbnailSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storefrontUploadGiftCardVideoMessagePost(apiVersion?: string, giftCardId?: string, videoMessageFile?: any, videoMessageThumbnailFile?: any, videoMessageSize?: number, videoMessageThumbnailSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadGiftCardsVideoMessageResultPayload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storefrontUploadGiftCardVideoMessagePost(apiVersion, giftCardId, videoMessageFile, videoMessageThumbnailFile, videoMessageSize, videoMessageThumbnailSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StorefrontApi - factory interface
 * @export
 */
export const StorefrontApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StorefrontApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontActiveImageAssetsGet(apiVersion?: string, options?: any): AxiosPromise<Array<GiftCardImageAssetViewModel>> {
            return localVarFp.storefrontActiveImageAssetsGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontCheckBalancePagePublicGet(apiVersion?: string, options?: any): AxiosPromise<CheckBalancePageSettingsViewModel> {
            return localVarFp.storefrontCheckBalancePagePublicGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontCheckbalancestyleCssGet(apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.storefrontCheckbalancestyleCssGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [paymentIntentId] 
         * @param {string} [code] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontCouponByCodeGet(paymentIntentId?: string, code?: string, apiVersion?: string, options?: any): AxiosPromise<StorefrontCoupon> {
            return localVarFp.storefrontCouponByCodeGet(paymentIntentId, code, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {StorefrontOrderViewModel} [storefrontOrderViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontCreateOrderAndStripeIntentPost(apiVersion?: string, storefrontOrderViewModel?: StorefrontOrderViewModel, options?: any): AxiosPromise<StorefrontOrderViewModel> {
            return localVarFp.storefrontCreateOrderAndStripeIntentPost(apiVersion, storefrontOrderViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontGetByCodeCodeGet(code: string, apiVersion?: string, options?: any): AxiosPromise<GiftCardDetailViewModel> {
            return localVarFp.storefrontGetByCodeCodeGet(code, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontGetByIdIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<GiftCardDetailViewModel> {
            return localVarFp.storefrontGetByIdIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontGetCustomEmbedScriptGet(apiVersion?: string, options?: any): AxiosPromise<string> {
            return localVarFp.storefrontGetCustomEmbedScriptGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontGetOrProcessOrderGet(id?: string, apiVersion?: string, options?: any): AxiosPromise<StorefrontOrderViewModel> {
            return localVarFp.storefrontGetOrProcessOrderGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontGiftToFriendPagePublicGet(apiVersion?: string, options?: any): AxiosPromise<GiftToFriendPageSettingsViewModel> {
            return localVarFp.storefrontGiftToFriendPagePublicGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontGifttofriendstyleCssGet(accountId?: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.storefrontGifttofriendstyleCssGet(accountId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontInitStorefrontOrderPost(apiVersion?: string, options?: any): AxiosPromise<StorefrontOrderViewModel> {
            return localVarFp.storefrontInitStorefrontOrderPost(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} giftCardToTopupId 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontInitStorefrontTopupOrderGiftCardToTopupIdPost(giftCardToTopupId: string, apiVersion?: string, options?: any): AxiosPromise<StorefrontOrderViewModel> {
            return localVarFp.storefrontInitStorefrontTopupOrderGiftCardToTopupIdPost(giftCardToTopupId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontPaymentIntentStatusGet(id?: string, apiVersion?: string, options?: any): AxiosPromise<string> {
            return localVarFp.storefrontPaymentIntentStatusGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [paymentIntentId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontRemoveCouponGet(paymentIntentId?: string, apiVersion?: string, options?: any): AxiosPromise<StorefrontOrderViewModel> {
            return localVarFp.storefrontRemoveCouponGet(paymentIntentId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [videoMessageUrl] 
         * @param {string} [videoMessageThumbnailUrl] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontRemoveVideoMessageGet(videoMessageUrl?: string, videoMessageThumbnailUrl?: string, apiVersion?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.storefrontRemoveVideoMessageGet(videoMessageUrl, videoMessageThumbnailUrl, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontSettingsGet(apiVersion?: string, options?: any): AxiosPromise<StorefrontSettingsViewModel> {
            return localVarFp.storefrontSettingsGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontStorefrontOrderGet(id?: string, apiVersion?: string, options?: any): AxiosPromise<StorefrontOrderViewModel> {
            return localVarFp.storefrontStorefrontOrderGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [paymentIntentId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontUpdateOrderAndStripeIntentGet(paymentIntentId?: string, apiVersion?: string, options?: any): AxiosPromise<StorefrontOrderViewModel> {
            return localVarFp.storefrontUpdateOrderAndStripeIntentGet(paymentIntentId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {string} [giftCardId] 
         * @param {any} [videoMessageFile] 
         * @param {any} [videoMessageThumbnailFile] 
         * @param {number} [videoMessageSize] 
         * @param {number} [videoMessageThumbnailSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontUploadGiftCardVideoMessagePost(apiVersion?: string, giftCardId?: string, videoMessageFile?: any, videoMessageThumbnailFile?: any, videoMessageSize?: number, videoMessageThumbnailSize?: number, options?: any): AxiosPromise<UploadGiftCardsVideoMessageResultPayload> {
            return localVarFp.storefrontUploadGiftCardVideoMessagePost(apiVersion, giftCardId, videoMessageFile, videoMessageThumbnailFile, videoMessageSize, videoMessageThumbnailSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StorefrontApi - object-oriented interface
 * @export
 * @class StorefrontApi
 * @extends {BaseAPI}
 */
export class StorefrontApi extends BaseAPI {
    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorefrontApi
     */
    public storefrontActiveImageAssetsGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return StorefrontApiFp(this.configuration).storefrontActiveImageAssetsGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorefrontApi
     */
    public storefrontCheckBalancePagePublicGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return StorefrontApiFp(this.configuration).storefrontCheckBalancePagePublicGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorefrontApi
     */
    public storefrontCheckbalancestyleCssGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return StorefrontApiFp(this.configuration).storefrontCheckbalancestyleCssGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [paymentIntentId] 
     * @param {string} [code] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorefrontApi
     */
    public storefrontCouponByCodeGet(paymentIntentId?: string, code?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return StorefrontApiFp(this.configuration).storefrontCouponByCodeGet(paymentIntentId, code, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {StorefrontOrderViewModel} [storefrontOrderViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorefrontApi
     */
    public storefrontCreateOrderAndStripeIntentPost(apiVersion?: string, storefrontOrderViewModel?: StorefrontOrderViewModel, options?: AxiosRequestConfig) {
        return StorefrontApiFp(this.configuration).storefrontCreateOrderAndStripeIntentPost(apiVersion, storefrontOrderViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorefrontApi
     */
    public storefrontGetByCodeCodeGet(code: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return StorefrontApiFp(this.configuration).storefrontGetByCodeCodeGet(code, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorefrontApi
     */
    public storefrontGetByIdIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return StorefrontApiFp(this.configuration).storefrontGetByIdIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorefrontApi
     */
    public storefrontGetCustomEmbedScriptGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return StorefrontApiFp(this.configuration).storefrontGetCustomEmbedScriptGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorefrontApi
     */
    public storefrontGetOrProcessOrderGet(id?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return StorefrontApiFp(this.configuration).storefrontGetOrProcessOrderGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorefrontApi
     */
    public storefrontGiftToFriendPagePublicGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return StorefrontApiFp(this.configuration).storefrontGiftToFriendPagePublicGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [accountId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorefrontApi
     */
    public storefrontGifttofriendstyleCssGet(accountId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return StorefrontApiFp(this.configuration).storefrontGifttofriendstyleCssGet(accountId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorefrontApi
     */
    public storefrontInitStorefrontOrderPost(apiVersion?: string, options?: AxiosRequestConfig) {
        return StorefrontApiFp(this.configuration).storefrontInitStorefrontOrderPost(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} giftCardToTopupId 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorefrontApi
     */
    public storefrontInitStorefrontTopupOrderGiftCardToTopupIdPost(giftCardToTopupId: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return StorefrontApiFp(this.configuration).storefrontInitStorefrontTopupOrderGiftCardToTopupIdPost(giftCardToTopupId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorefrontApi
     */
    public storefrontPaymentIntentStatusGet(id?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return StorefrontApiFp(this.configuration).storefrontPaymentIntentStatusGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [paymentIntentId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorefrontApi
     */
    public storefrontRemoveCouponGet(paymentIntentId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return StorefrontApiFp(this.configuration).storefrontRemoveCouponGet(paymentIntentId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [videoMessageUrl] 
     * @param {string} [videoMessageThumbnailUrl] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorefrontApi
     */
    public storefrontRemoveVideoMessageGet(videoMessageUrl?: string, videoMessageThumbnailUrl?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return StorefrontApiFp(this.configuration).storefrontRemoveVideoMessageGet(videoMessageUrl, videoMessageThumbnailUrl, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorefrontApi
     */
    public storefrontSettingsGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return StorefrontApiFp(this.configuration).storefrontSettingsGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorefrontApi
     */
    public storefrontStorefrontOrderGet(id?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return StorefrontApiFp(this.configuration).storefrontStorefrontOrderGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [paymentIntentId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorefrontApi
     */
    public storefrontUpdateOrderAndStripeIntentGet(paymentIntentId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return StorefrontApiFp(this.configuration).storefrontUpdateOrderAndStripeIntentGet(paymentIntentId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {string} [giftCardId] 
     * @param {any} [videoMessageFile] 
     * @param {any} [videoMessageThumbnailFile] 
     * @param {number} [videoMessageSize] 
     * @param {number} [videoMessageThumbnailSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorefrontApi
     */
    public storefrontUploadGiftCardVideoMessagePost(apiVersion?: string, giftCardId?: string, videoMessageFile?: any, videoMessageThumbnailFile?: any, videoMessageSize?: number, videoMessageThumbnailSize?: number, options?: AxiosRequestConfig) {
        return StorefrontApiFp(this.configuration).storefrontUploadGiftCardVideoMessagePost(apiVersion, giftCardId, videoMessageFile, videoMessageThumbnailFile, videoMessageSize, videoMessageThumbnailSize, options).then((request) => request(this.axios, this.basePath));
    }
}
