import React, {
  Component,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import {
  Button,
  Row,
  Modal,
  Form,
  Container,
  Col,
  InputGroup,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  StorefrontApi,
  StorefrontOrderViewModel,
  StorefrontSettingsViewModel,
} from "../../../apiclient";
import { apiConfig } from "../../../config";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import "react-quill/dist/quill.snow.css";
import "../PublicStorefront.css";
import CurrencyUtil from "../../../Helpers/CurrencyUtil";

//import "./assets.css";
const PublicStorefrontTopupAddToCartComponent = (props: any) => {
  const [validated, setValidated] = useState(false);
  const [model, setModel] = useState(props.model as StorefrontOrderViewModel);
  const [settings, setSettings] = useState({} as StorefrontSettingsViewModel);
  const [customAmount, setCustomAmount] = useState(0);
  const [amount, setAmount] = useState(0);
  //let { id } = useParams();

  let storefrontApi = new StorefrontApi(
    apiConfig.configuration,
    apiConfig.baseUrl
  );

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = () => {
    storefrontApi
      .storefrontSettingsGet()
      .then((response) => {
        var data = response.data;
        setSettings(data);
      })
      .catch((error: any) => {
        console.log("get error", error);
      });
  };

  const buyOnClick = (amount: number) => {
    var productTitle = `$${amount} gift card`;
    props.onSelected(amount, productTitle);
  };

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (form.checkValidity() === true) {
      var productTitle = `$${customAmount} gift card`;
      props.onSelected(customAmount, productTitle);
    }
  };

  const handleCustomAmountChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
      const name = event.target.name;
      if (parseInt(value) < 2000) {
          setCustomAmount(parseInt(value));
      }
  };

  return (
    <React.Fragment>
      {model && (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="row g-5">
            <div className="col-12">
              <div className="list-group list-group-flush p-5 pt-0">
                {settings &&
                  settings.variants?.map((variant, i) => (
                    <>
                      {/* small only */}
                      <div key={"variant-small" + i} className=" list-group-item align-items-center d-block d-sm-none">
                        <div className="flex-fill">
                          <div className="d-block h6 font-semibold mb-1">
                            {variant.name}
                          </div>
                          <div className="d-flex mx-n1"></div>
                        </div>
                        <div className="mt-5">
                          <button
                            type="button"
                            className="btn btn-sm btn-primary w-full btn-custom"
                            onClick={() => buyOnClick(variant.price!)}
                          >
                            Top up {CurrencyUtil.formatCurrency(variant.price!, settings.isoCurrencyCode)}
                          </button>
                        </div>
                      </div>
                      {/* large */}
                      <div key={"variant-large" + i} className=" list-group-item align-items-center d-none d-sm-block">
                        <div className="d-flex">
                          <div className="flex-fill">
                            <div className="d-block h6 font-semibold mb-1 pt-2">
                            {CurrencyUtil.formatCurrency(variant.price!, settings.isoCurrencyCode)}
                            </div>
                            <div className="d-flex mx-n1"></div>
                          </div>
                          <div className="ms-auto text-end">
                            <button
                              type="button"
                              className="btn btn-sm btn-primary btn-custom"
                              onClick={() => buyOnClick(variant.price!)}
                            >
                              Top up
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                {/* small only */}
                <div className=" list-group-item align-items-center d-block d-sm-none">
                  <div className="flex-fill">
                    <div className="d-block h6 font-semibold mb-1">
                      Choose an amount...
                    </div>
                    <div className="d-flex mx-n1"></div>
                  </div>
                  <div className="mt-5">
                    <InputGroup className="mb-3">
                      <Form.Control
                        size="sm"
                        placeholder="0.00"
                        aria-label="0.00"
                        type="number"
                        value={customAmount}
                                              isValid={customAmount > 0 && customAmount < 2000}
                        onChange={handleCustomAmountChange}
                      />
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary btn-custom"
                        onClick={() => buyOnClick(customAmount)}
                      >
                        Buy
                      </button>
                    </InputGroup>
                  </div>
                </div>
                {/* large */}
                <div className=" list-group-item align-items-center d-none d-sm-block">
                  <div className="d-flex">
                    <div className="flex-fill">
                      <div className="d-block h6 font-semibold mb-1 pt-2">
                        Choose an amount...
                      </div>
                      <div className="d-flex mx-n1"></div>
                    </div>
                    <div className="ms-auto text-end">
                      <InputGroup className="mb-3">
                        <Form.Control
                          size="sm"
                          style={{ maxWidth: "100px" }}
                          placeholder="0.00"
                          aria-label="0.00"
                          type="number"
                          value={customAmount}
                                                  isValid={customAmount > 0 && customAmount < 2000}
                          onChange={handleCustomAmountChange}
                        />
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary btn-custom"
                          onClick={() => buyOnClick(customAmount)}
                        >
                          Top up
                        </button>
                      </InputGroup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </React.Fragment>
  );
};

export default PublicStorefrontTopupAddToCartComponent;
